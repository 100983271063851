<template>
  <div class="px-5" id="page-books">
    <div v-if="waiting_fortnox_status" class="alert alert-custom alert-notice alert-light-info fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">{{ $t('BOOK.CHECK_FORTNOX_STATUS') }}</div>
    </div>


    <div v-if="!waiting_fortnox_status && !fortnox_status" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">{{ $t('BOOK.FORTNOX_NOT_CONNECTED') }}</div>
    </div>

    <div v-if="!waiting_fortnox_status && fortnox_status" class="alert alert-custom alert-notice alert-light-success fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">{{ $t('BOOK.FORTNOX_IS_CONNECTED') }}</div>
    </div>


    <DinteroSettlementUploadModal
      ref="dinteroUploadModal"
    />

    <h5 class="mb-6 ml-2">{{ $t('BOOK.VOUCHERS') }}</h5>

    <b-card title="" class="mb-2" hide-footer>
      <v-tabs background-color="white" color="dark" left>
        <v-tab>{{ $t('BOOK.BOOKED') }}</v-tab>
        <v-tab>{{ $t('BOOK.NOT_BOOKED') }}</v-tab>
        <v-tab>{{ $t('FORTNOX.FORTNOX') }}</v-tab>

        <v-tab-item>

          <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
            <input hidden type="file" id="file" ref="file" v-on:change="handleFileUpload()" />
            <a
              href="#"
              class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
              @click="uploadDinteroFile"
              ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('BOOK.UPLOAD_DINTERO_FILE')}}</a
            >

            <a
              href="#"
              class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
              @click.prevent="create_book_clicked"
              ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('BOOK.CREATE_VOUCHER')}}</a
            >

          </div>


          <BookDoneView :books="syncedBooks" @selectBook="selectBook" />
        </v-tab-item>

        <v-tab-item>

          <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
            <input hidden type="file" id="file" ref="file" v-on:change="handleFileUpload()" />
            <a
              href="#"
              class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
              @click="uploadDinteroFile"
              ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('BOOK.UPLOAD_DINTERO_FILE')}}</a
            >

            <a
              href="#"
              class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
              @click.prevent="create_book_clicked"
              ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('BOOK.CREATE_VOUCHER')}}</a
            >
          </div>

          <BookWaitView :books="unsyncedBooks" @selectBook="selectBook" />
        </v-tab-item>

        <v-tab-item>
          <Fortnox :company="system_company" />

        </v-tab-item>

      </v-tabs>
    </b-card>

    <BookEditorModal
      ref="book-editor-modal"
      :item="item"
      @created="book_created"
      @updated="book_updated"
    />

  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import BookDoneView from '@/view/pages/ml/book/BookDoneView.vue';
import BookWaitView from '@/view/pages/ml/book/BookWaitView.vue';
import BookEditorModal from '@/view/pages/ml/book/BookEditorModal.vue';
import Fortnox from '@/view/pages/ml/settings/Fortnox.vue';
import DinteroSettlementUploadModal from '@/view/pages/ml/book/DinteroSettlementUploadModal.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

const BOOK_STATUS = {
  DO_NOT_SYNC_FORTNOX: 'DO_NOT_SYNC_FORTNOX',
  TO_BE_SYNCED_FORTNOX: 'TO_BE_SYNCED_FORTNOX',
  PENDING_FORNOX: 'PENDING_FORNOX',
  IN_FORTNOX: 'IN_FORTNOX'
};

export default {
  name: 'book-page',
  components: {
    BookDoneView,
    BookWaitView,
    BookEditorModal,
    DinteroSettlementUploadModal,
    Fortnox
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'sid']),
    syncedBooks() {
      return this.books.filter(book => book.status === BOOK_STATUS.IN_FORTNOX);
    },
    unsyncedBooks() {
      return this.books.filter(book => book.status !== BOOK_STATUS.IN_FORTNOX);
    },

  },
  mixins: [ toasts ],
  mounted() {
    this.load_system_company();
    this.loadData();
  },
  data() {
    return {
      item: null,
      system_company: null,
      waiting_fortnox_status: true,
      fortnox_status: false,
      access_token: '',
      books: [],
      BOOK_STATUS: BOOK_STATUS
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
      }
    },
    currentPeriodId(newValue, oldValue) {}
  },
  methods: {
    book_created(book) {
      this.item = book;
      this.books.push(book);
    },
    book_updated(book) {
      let index = this.books.findIndex(item => item.book_id == book.book_id);
      this.books[index] = book;
      // Vue3 compatability for Vue.set
      this.books = [...this.books];
    },
    uploadDinteroFile() {
      this.$refs['dinteroUploadModal'].show();
    },
    checkFortnoxStatus() {
      axios
        .get(`/fortnox/status`)
        .then(res => {
          this.waiting_fortnox_status = false;
          this.fortnox_status = res.status === 200;
        })
        .catch(err => {
          this.waiting_fortnox_status = false;
          console.error('status error, ', err);
        });
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.uploadFile();
    },
    saveFile() {
      let that = this;
      axios
        .put(`/file/${this.item.file_id}`, this.item)
        .then(res => {
          if (res.status === 200) {
            let index = that.files.findIndex(item => item.file_id == res.data.file_id);
            that.files[index] = res.data;
            // Vue3 compatability for Vue.set
            that.files = [...that.files];
            this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.UPDATED'));
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FILE.UNABLE_UPDATE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FILE.UNABLE_UPDATE'));
        });
    },
    selectFileForUpload() {
      this.$refs.file.click();
    },
    uploadFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('file_id', this.item.file_id);
      formData.append('file_source', 'file');
      formData.append('file_type', 'FILE_SETTLEMENT');
      formData.append('form_file_id', this.item.file_id);
      formData.append('company_id', this.currentCompanyId);
      formData.append('period_id', this.currentPeriodId);

      axios
        .post('/fileupload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.UPLOADED'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FILE.UNABLE_UPLOAD'));
        });
    },
    getFilename(path) {
      return path.replace(/^.*[\\\/]/, '');
    },


    create_book_clicked() {

      this.item = {
        company_id: this.currentCompanyId,
        period_id: this.currentPeriodId,
        id: null,
        message: null,
        rows: [
          { account: null, debit: '0', credit: '0', ks: '', project: '', description: '' },
          { account: null, debit: '0', credit: '0', ks: '', project: '', description: '' }
        ]
      };

      this.$nextTick(()=>{
        this.$refs['book-editor-modal'].show();
      });

    },

    selectBook(book) {
      this.item = {
        ...book,
        rows: [
          ...book.debits.map(debit => ({
            ...debit,
            debit: debit.amount,
            credit: '0'
          })),

          ...book.credits.map(credit => ({
            ...credit,
            debit: '0',
            credit: credit.amount
          }))
        ]
      };

      this.$nextTick(()=>{
        this.$refs['book-editor-modal'].show();
      });
    },
    load_system_company() {

      axios
        .get(`/company/admin?company_id=${this.sid}`)
        .then(res => {
          if (res.status === 200) {
            this.system_company = res.data;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), );
        });
    },

    loadData() {
      this.checkFortnoxStatus();

      axios
        .get(`/book/period/${this.currentPeriodId}/company/${this.currentCompanyId}`)
        .then(response => {
          if (response.status === 200) {
            this.books = response.data;
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>
